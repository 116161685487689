<template>
    <b-modal centered
             hide-footer
             content-class="rounded-xl"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <template v-slot:modal-title>
            <strong>Deleting "{{ targetValue }}"</strong>
        </template>
        <p>
            You are deleting "<strong>{{ targetValue }}</strong>".
            This is an irreversible acton. Please enter the name again to proceed.
        </p>
        <div class="form-row">
            <b-form-group class="col-md-12"
                          label-for="challenge"
                          label="Name of item to be deleted">
                <b-input-group>
                    <b-form-input id="challenge"
                                  required
                                  v-bind:type="type"
                                  v-model="challengeReply">
                    </b-form-input>
                    <b-input-group-append>
                        <button class="btn btn-danger"
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-bind:disabled="!allowDelete"
                                v-on:click="confirm">
                            Delete
                        </button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, } from "bootstrap-vue";
export default {
    name: "ModalDeleteConfirm",
    components: {
        BModal, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
    },
    props: {
        value: {
            type: Boolean,
        },
        type: {
            type: String,
            default: "text",
        },
        target: {
            type: Object,
        },
        field: {
            type: String,
            default: "name",
        },
        callback: {
            type: Function,
        },
    },
    data () {
        return {
            isLoading: false,
            challengeReply: "",
        };
    },
    computed: {
        targetValue () {
            return this.target?.[this.field] ?? "";
        },
        allowDelete () {
            return this.challengeReply === this.targetValue;
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        async confirm () {
            if (this.challengeReply === this.targetValue && typeof this.callback === "function") {
                this.isLoading = true;
                await this.callback();
                this.isLoading = false;
                this.hideModal();
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
